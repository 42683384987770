<template>
  <div class="container">
    <div>
      <div class="search-container">
        <el-form
            :inline="true"
            :model="formInline"
            @submit.native.prevent
            class="demo-form-inline"
        >
          <el-form-item label="客户手机">
            <el-input v-model="formInline.client_mobile" placeholder="请输入客户手机"></el-input>
          </el-form-item>
          <el-form-item label="服务手机">
            <el-input v-model="formInline.service_mobile" placeholder="请输入服务手机"></el-input>
          </el-form-item>
          <el-form-item label="服务时间">
            <el-date-picker
                v-model="date"
                value-format="yyyy-MM-dd"
                type="daterange"
                @change="dateHandle"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button v-indentify="'consult:list:view:addOrder'" type="primary" icon="el-icon-plus"
                       @click="handle('add')">添加咨询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-container">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              width="200"
              prop="order_id"
              label="订单ID">
          </el-table-column>
          <el-table-column
              width="200"
              prop="service_record_id"
              label="咨询ID">
          </el-table-column>
          <el-table-column
              prop="client_mobile"
              label="客户手机">
          </el-table-column>
          <el-table-column
              prop="service_mobile"
              label="服务手机">
          </el-table-column>
          <el-table-column
              width="250"
              label="服务时间">
            <template slot-scope="scope">
              <div>开始：{{ scope.row.service_start_time | dateFormat }}</div>
              <div>结束：{{ scope.row.service_end_time | dateFormat }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="咨询时长"
              width="150">
            <template slot-scope="scope">
              <div style="color: #216EFF;">时长：{{ scope.row.minute }}分钟</div>
            </template>
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              label="支付状态">
            <template slot-scope="scope">
              <div>
                <StatusTag :state="scope.row.status" :data="payStatusEnum"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"
              width="190">
            <template slot-scope="scope">
              <span>{{ scope.row.create_time | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="200"
              label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.status !== 2">
                <el-button v-indentify="'order:list:view:serviceRecordDetail'" type="primary" size="small"
                           @click="handle('edit', scope.row.order_id)">设置服务
                </el-button>
                <!--<el-button v-indentify="'consult:list:view:paySmsPlan'" type="primary" size="small" @click="handle('edit', scope.row.order_id)">短信</el-button>-->
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <PcPaginationView @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :total="total"
                        :page-size="page_size"/>
    </div>
    <OrderDrawer
        :visible.sync="showDrawer" :title="handleFlag === 'add' ? '添加咨询' : '编辑咨询'" :drawerForm="drawerForm"
        :flag="handleFlag" @cancel="cancelDrawer" @confirm="confirmDrawer">
      <el-form-item label="开始时间">
        <el-date-picker
            style="width: 100%"
            v-model="drawerForm.service_start_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            :clearable="false"
            placeholder="选择服务开始时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
            style="width: 100%"
            v-model="drawerForm.service_end_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            :clearable="false"
            placeholder="选择服务结束时间">
        </el-date-picker>
      </el-form-item>
    </OrderDrawer>
  </div>
</template>

<script>
import Mixin from '@/utils/Mixin'
import { mapState, mapActions } from 'vuex'
import PcPaginationView from '@/components/Pagination/pc'
import { consultListApi, serviceRecordDetailApi } from '@/api/order'
import OrderDrawer from '@/components/pc/OrderDrawer'
import StatusTag from '@/components/pc/common/StatusTag'

export default {
  name: 'PcConsultList',
  mixins: [Mixin],
  components: {
    PcPaginationView,
    OrderDrawer,
    StatusTag
  },
  computed: {
    ...mapState('statusTagEnum', ['payStatusEnum'])
  },
  data () {
    return {
      formInline: {
        client_mobile: '',
        service_mobile: '',
        start_time: '',
        end_time: ''
      },
      drawerForm: {
        order_id: '',
        mobile: '',
        service_mobile: '',
        service_item_id: '',
        service_start_time: '',
        service_end_time: ''
      },
      date: [],
      page: 1,
      page_size: 10,
      total: 0,
      tableData: [],
      handleFlag: '',
      showDrawer: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    ...mapActions('selectOptions', ['getServiceItem']),
    async getList () {
      const data = await consultListApi({
        page: this.page,
        page_size: this.page_size,
        ...this.formInline
      })
      if (data.code === 1) {
        this.tableData = data.data.data
        this.total = data.data.total
      }
    },
    async getServiceRecordDetail () {
      const { order_id } = this.drawerForm
      const data = await serviceRecordDetailApi({ order_id })
      if (data.code === 1) {
        if (data.data === null) return
        const detail = data.data
        const service_start_time = this.updateDrawerForm(detail.start_time)
        const service_end_time = this.updateDrawerForm(detail.end_time)
        this.drawerForm = {
          order_id,
          mobile: detail.client_mobile,
          service_mobile: detail.service_mobile,
          service_item_id: detail.id,
          service_start_time,
          service_end_time
        }
      }
    },
    async handle (flag, order_id = '') {
      if (flag === 'edit') {
        this.drawerForm.order_id = order_id
        await this.getServiceRecordDetail()
      }
      await this.getServiceItem()
      this.showDrawer = true
      this.handleFlag = flag
    },
    cancelDrawer () {
      const { drawerForm } = this.$data
      for (const drawerFormKey in drawerForm) {
        drawerForm[drawerFormKey] = ''
      }
      this.drawerForm = drawerForm
    },
    confirmDrawer () {
      this.getList()
    }, // 查询
    async onSearch () {
      this.page = 1
      await this.getList()
    },
    handleSizeChange (val) {
      this.page_size = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    /**
     * 时间区间change
     * @param date
     */
    dateHandle (date) {
      if (date) {
        this.formInline.start_time = date[0]
        this.formInline.end_time = date[1]
      } else {
        this.formInline.start_time = ''
        this.formInline.end_time = ''
      }
    }, // 时间戳转时间
    updateDrawerForm (date) {
      return this.$dateFormat(date, 'YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>

.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.chat-content {
  max-width: 300px;
  word-break: break-all;
  font-size: 12px;
  margin: 0 10px;
}
</style>
